<template>
    <div id="CustomerRegister">
        <v-container fluid app>
          <v-card>
            <v-toolbar flat class="rounded-lg">
                <div class="d-flex align-start mr-4">
                    <v-icon x-large color="primary">
                        {{ tableIcon }}
                    </v-icon>
                </div>
                <div class="d-flex flex-column align-start">
                    <span class="text-h6 font-weight-regular">{{ tableName }}</span>
                    <!-- <div class="mb-2 d-flex align-center">
                        <v-icon small color="primary" class="mr-2">mdi-information-outline</v-icon>
                        <span class="text-caption">{{ tableDescription }}</span>
                    </div> -->
                </div>

                <v-spacer></v-spacer>

                <v-text-field
                    v-model="search"
                    label='Pesquisar'
                    append-icon="mdi-magnify"
                    hide-details
                    single-line
                    dense
                    clearable
                    class="mr-4"
                    style="width: 1%;"
                />

                <v-tooltip top v-if="allowDuplicate && createPermission">
                    <template v-slot:activator="{ on }">
                        <v-btn
                            color="secondary" dark
                            v-on="on"
                            class="mb-2 mr-4 pa-0"
                            min-width="48px"
                            @click="$root.$emit('duplicateLine', tab)"
                        >
                            <v-icon>mdi-content-duplicate</v-icon>
                        </v-btn>
                    </template>
                    <span>Duplicar item selecionado</span>
                </v-tooltip>

                <v-tooltip top v-if="allowEdit && updatePermission">
                    <template v-slot:activator="{ on }">
                        <v-btn
                            color="secondary" dark
                            v-on="on"
                            class="mb-2 mr-4 pa-0"
                            min-width="48px"
                            @click="$root.$emit('editVendor', tab)"
                        >
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                    <span>Editar item selecionado</span>
                </v-tooltip>

                <v-tooltip top v-if="allowDelete && deletePermission">
                    <template v-slot:activator="{ on }">
                        <v-btn
                            color="secondary" dark
                            v-on="on"
                            class="mb-2 mr-4 pa-0"
                            min-width="48px"
                            @click="$root.$emit('massDelete', tab)"
                        >
                            <v-icon>mdi-delete-sweep</v-icon>
                        </v-btn>
                    </template>
                    <span>Deletar itens selecionados</span>
                </v-tooltip>

                <v-tooltip top v-if="createPermission">
                    <template v-slot:activator="{ on }">
                        <v-btn
                            color="primary"
                            v-on="on"
                            class="mb-2 mr-4 pa-0"
                            min-width="48px"
                            @click="$root.$emit('newVendor', tab)"
                        >
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </template>
                    <span>Novo item</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{on,attrs}">
                        <v-btn
                            color="primary"
                            v-on="on"
                            v-bind="attrs"
                            class="mb-2 mr-4 pa-0"
                            min-width="48px"
                            @click="makeLoad = !makeLoad"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Atualizar</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{on}">
                        <v-btn
                            :class="saveBtnColor[0]"
                            v-on="on"
                            class="mb-2 mr-4 pa-0"
                            min-width="48px"
                            @click="$root.$emit('massSave', tab)"
                        >
                            <v-icon> {{ saveBtnColor[1] }}</v-icon>
                        </v-btn>
                    </template>
                    <span>
                        {{ unsavedChanges ? 'Salvar edições' : 'Nenhum dado foi alterado'}}
                    </span>
                </v-tooltip>

                <v-tooltip top v-if="crudPermission">
                    <template v-slot:activator="{on,attrs}">
                        <v-btn
                            @click="$root.$emit('importExcel', tab)"
                            color="primary"
                            v-on="on"
                            v-bind="attrs"
                            class="mb-2 mr-4 pa-0"
                            min-width="48px"
                        >
                            <v-icon>mdi-file-excel</v-icon>
                        </v-btn>
                        <input
                            ref="uploader"
                            class="d-none"
                            type="file"
                        >
                    </template>
                    <span>Importar Excel</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{on,attrs}">
                        <v-btn
                            @click="testExcel = tab"
                            color="primary"
                            v-on="on"
                            v-bind="attrs"
                            class="mb-2 mr-4 pa-0"
                            min-width="48px"
                        >
                            <v-icon>mdi-microsoft-excel</v-icon>
                        </v-btn>
                    </template>
                    <span>Exportar Excel</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            color="primary" dark
                            v-on="on"
                            class="mb-2 pa-0"
                            min-width="48px"
                            @click="closeTable()"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </template>
                    <span>Fechar</span>
                </v-tooltip>

                <v-dialog v-model="dialogCloseTable" max-width="600px">
                    <v-card>
                        <div class="d-flex justify-center">
                            <v-card-title class="headline text-center">Você possui edições não salvas. Tem certeza de que deseja fechar a tabela?</v-card-title>
                        </div>
                        <v-card-actions class="px-6 pb-6">
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="dialogCloseTable = false" class="mr-4">Cancelar</v-btn>
                            <v-btn color="primary" @click="$router.push('/')">Confirmar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <!-- <v-dialog v-model="dialogDelete" max-width="600px">
                    <v-card>
                        <div class="d-flex justify-center">
                            <v-card-title class="headline text-center">Tem certeza de que deseja excluir este item?</v-card-title>
                        </div>
                        <v-card-actions class="px-6 pb-6">
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="closeDelete" class="mr-4">Cancelar</v-btn>
                            <v-btn color="primary" @click="deleteItemConfirm" :loading="deleting">Confirmar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog> -->
            </v-toolbar>
          <v-divider class="mx-4 mb-2"></v-divider>

            <v-row no-gutters class="d-flex justify-start align-center mb-3" style="height: 24px !important; width: 100%;">
                <v-col class="d-flex justify-start" style="height: 24px !important;">
                    <v-tabs  
                        v-model="tab"
                        class="mx-4"
                        height="24px"
                    >
                        <v-tabs-slider color="primary"></v-tabs-slider>

                        <v-tab>
                            <span class="text-caption text-capitalize"> Cliente</span>
                        </v-tab>
                        <v-tab>
                            <span class="text-caption text-capitalize"> Endereço</span>
                        </v-tab>
                        <v-tab>
                            <span class="text-caption" style="text-transform: none;"> Parâmetros do cliente</span>
                        </v-tab>
                    </v-tabs>
                </v-col>
            </v-row>
            <v-tabs-items v-model="tab">
                <v-tab-item>
                  <LegalPerson @unsavedChanges="setUnsaved" @setTestExcel="testExcel = -1" @forceLoad="makeLoad = !makeLoad" :testExcel="testExcel" :makeLoad="makeLoad"/>
                </v-tab-item>
                <v-tab-item>
                  <Address @unsavedChanges="setUnsaved" @setTestExcel="testExcel = -1" @forceLoad="makeLoad = !makeLoad" :customerType="2" :testExcel="testExcel" :makeLoad="makeLoad"/>
                </v-tab-item>
                <v-tab-item>
                  <Param @unsavedChanges="setUnsaved" @setTestExcel="testExcel = -1" @forceLoad="makeLoad = !makeLoad" :customerType="2" :testExcel="testExcel" :makeLoad="makeLoad"/>
                </v-tab-item>
            </v-tabs-items>

          <slot name="filters"></slot>
          </v-card>

        </v-container>
    </div>
</template>

<script>
import LegalPerson from '@/components/znap/customer/LegalPerson.vue'
import Address from '@/components/znap/customer/CustomerAddress.vue'
import Param from '@/components/znap/customer/CustomerParamValue.vue'

export default {
    name:'VendorRegister',

    components: { 
        LegalPerson,
        Address,
        Param
    },

    computed: {
        endpoint() {
            return [ this.$ipVendor, 'vendor-type' ]
        },
    },

    data: () => ({
        tableName: 'Pessoa jurídica',
        tableIcon: 'mdi-briefcase-account',
        tableDescription: 'Forneça uma descrição para esta tabela.',
        tableRows: 10000,

        options: [],

        noDataMessage: '',
        noData: true,

        // Filters
        filterConditions: [],
        loadTable: false,
        clearFilters: false,

        tab: 0,

        search: '',
        dialog: false,
        dialogCloseTable: false,
        closeTableDialog: false,
        unsavedChanges: [false, false, false],
        selected: [0, 0, 0],

        saveBtnColor: ['primary', 'mdi-content-save'],
        allowEdit: false,
        allowDelete: false,
        allowDuplicate: false,

        testExcel: -1,
        makeLoad: false,

        crudPermission: true,
        createPermission: true,
        updatePermission: true,
        deletePermission: true,
    }),

    watch: {
        search (val) {
            this.$root.$emit('searchVendor', val)
        },

        tab (val) {
            if (this.unsavedChanges[val]) {
                this.saveBtnColor = ['secondary', 'mdi-content-save-alert']
            } else {
                this.saveBtnColor = ['primary', 'mdi-content-save']
            }
            if (this.selected[val] > 0) {
                this.allowDelete = true
            } else {
                this.allowDelete = false
            }
            if (this.selected[val] === 1) {
                this.allowEdit = true
                this.allowDuplicate = true
            } else {
                this.allowEdit = false
                this.allowDuplicate = false
            }
        }
    },

    created () {
        const permissions = this.$getPermissions(this.$route.path)
        for (let key in permissions) {
            this[key] = permissions[key]
        }

        // this.$root.$on('unsavedChanges', ($event) => {
        //     this.unsavedChanges[$event.tab] = $event.unsaved
        // })
        this.$root.$on('selectedChanges', ($event) => {
            this.selected[$event.tab] = $event.selected
            if (this.selected[this.tab] > 0) {
                this.allowDelete = true
            } else {
                this.allowDelete = false
            }
            if (this.selected[this.tab] === 1) {
                this.allowEdit = true
                this.allowDuplicate = true
            } else {
                this.allowEdit = false
                this.allowDuplicate = false
            }
        })
        
        this.$root.$on('forceLoad', ($event) => {
            this.$root.$emit('makeLoad', $event)
        })

    },

    methods: {
        populateFilterConditions(selectionArray, requiredFilter) {
            this.filterConditions = []

            if (requiredFilter) {
                this.filterConditions.push({
                    AndOr: 'AND',
                    column: selectionArray[0].column,
                    operator: '=',
                    value: selectionArray[0].id,
                    required: true
                })

                this.loadTable = true
            } else {
                if (selectionArray.length !== 0) {
                    selectionArray.forEach(selection => {
                        
                        let required = false
                        this.options.forEach(option => {    
                            if (option.required && selection.column === option.column) {
                                required = true
                            }
                        })

                        if (selection.id === undefined) {
                            this.filterConditions = []
                        } else {
                            if (selection.id.length > 1) {
                                this.filterConditions.push({
                                    AndOr: 'AND',
                                    column: selection.column,
                                    operator: 'IN',
                                    value: selection.id,
                                    required
                                })
                            } else {
                                this.filterConditions.push({
                                    AndOr: 'AND',
                                    column: selection.column,
                                    operator: '=',
                                    value: selection.id,
                                    required
                                })
                            }             
                        }
                    })
                }
            }
        },

        setNoDataMessage(message) {
            this.noDataMessage = message
        },

        setNoData(payload) {
            this.noData = payload
        },

        setFilterConditions(array) {
            this.filterConditions = array

            if (array.length === 0) {
                this.clearFilters = true
            }

            this.options.forEach(option => {
                if (option.required) {
                    this.loadTable = true
                } 
            })
        },

        setClearFilters(payload) {
            this.clearFilters = payload
        },

        clearNotRequiredOptionsItems() {
            this.options.forEach(option => {
                if (!option.required) {
                    option.items = []
                } 
            })
        },

        setLoadTable(payload) {
            this.loadTable = payload
        },

        exportVendor (option) {
            this.$root.$emit('exportType', option)
            // if (option === 0) {
            //    this.$root.$emit('exportType')
            // }
            // if (option === 1) {
            //    this.$root.$emit('exportAddress')
            // }
            // if (option === 2) {
            //    this.$root.$emit('exportVendor')
            // }
        },

        closeTable() {
            let unsaved = false
            this.unsavedChanges.forEach((changed) => {
                changed ? unsaved = true : false
            })
            if (!unsaved) {
                this.$router.push('/')
            } else {
                this.dialogCloseTable = true
            }
        },

        setUnsaved (e) {
            this.unsavedChanges[e.tab] = e.unsaved
            if (this.unsavedChanges[this.tab]) {
                this.saveBtnColor = ['secondary', 'mdi-content-save-alert']
            } else {
                this.saveBtnColor = ['primary', 'mdi-content-save']
            }
        }
    },

}
</script>

<style>
</style>