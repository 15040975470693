<template>
    <div id="LegalPerson">
        <ZnapTable ref="znapTable"
            :endpoint="endpoint"
            :tableName="tableName"
            :tableIcon="tableIcon"
            :tableDescription="tableDescription"
            :options="options"
            :optionsAddress="optionsAddress"
            :optionsClient="optionsClient"
            :client="true"
            :show="false"
            :hasTabs="true"
            :tab="0"
            :testExcel="testExcel"
            :makeLoad="makeLoad"
            :isGovernment="isGovernment"
            :tableRows="tableRows"
            :noData="noData"
            @setNoData="setNoData"
            :noDataMessage="noDataMessage"
            @setNoDataMessage="setNoDataMessage"
            :filterConditions="filterConditions"
            @setFilterConditions="setFilterConditions"
            @clearFilters="setFilterConditions"
            :loadTable="loadTable"
            @setLoadTable="setLoadTable"
            @clearNotRequiredOptionsItems="clearNotRequiredOptionsItems"
            @unsavedChanges="sendUnsaved"
            @setTestExcel="$emit('setTestExcel')"
            @forceLoad="$emit('forceLoad')"
        >
            <!-- // Filters -->
            <template v-slot:filters>
                <ZnapFilters ref="znapFilters"
                    :filterComponents="options"
                    :noData="noData"
                    @setSelection="populateFilterConditions"
                    :clearFilters="clearFilters"
                    @setClearFilters="setClearFilters"
                >
                </ZnapFilters>
            </template>
        </ZnapTable>
    </div>
</template>

<script>
import Vue from 'vue'
import ZnapTable from '@/components/znap/ZnapTabs.vue'
import ZnapFilters from '@/components/znap/Filters.vue'

export default {
    name:'LegalPerson',

    components: { ZnapTable, ZnapFilters },

    props: {  
        isGovernment: { type: Boolean, default: false },
        testExcel: {
            type: Number,
        },
        makeLoad: {
            type: Boolean,
        }
    },

    computed: {
        endpoint() {
            return [ 
                this.$ipCustomer,
                'legal-person',
                {
                    conditions: [ 
                        {
                            AndOr: "AND",
                            column: "id_customer_type",
                            operator: "=",
                            value: 2
                        }
                    ]
            } ]
        },
    },

    data: () => ({
        tableName: 'Pessoa jurídica',
        tableIcon: 'mdi-briefcase-account',
        tableDescription: 'Forneça uma descrição para esta tabela.',
        tableRows: 10000,

        options: [
            {
                column: 'id_customer',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Nome do cliente',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipCustomer, 'customer' ],
                conditions: [ 
                    {
                        AndOr: "AND",
                        column: "id_customer_type",
                        operator: "=",
                        value: 2
                    }
                ]
            }
            // {
            //     column: 'id_customer_group',
            //     is: 'ComboBox',
            //     items: [],
            //     multiple: false,
            //     label: 'Grupo do cliente',
            //     required: false,
            //     filterable: false,
            //     endpoint: [ Vue.prototype.$ipCustomer, 'customer-group' ]
            // },
        ],

        optionsAddress: [
            {
                column: 'id_address_type',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Tipo de endereço',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipCustomer, 'address-type' ]
            },
        ],

        optionsClient: [
            {
                column: 'id_customer_group',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Grupo do cliente',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipCustomer, 'customer-group' ]
            },
        ],

        noDataMessage: '',
        noData: true,

        // Filters
        filterConditions: [],
        loadTable: false,
        clearFilters: false,
    }),

    methods: {
        populateFilterConditions(selectionArray, requiredFilter) {
            this.filterConditions = []

            if (requiredFilter) {
                this.filterConditions.push({
                    AndOr: 'AND',
                    column: selectionArray[0].column,
                    operator: '=',
                    value: selectionArray[0].id,
                    required: true
                })

                this.loadTable = true
            } else {
                if (selectionArray.length !== 0) {
                    selectionArray.forEach(selection => {
                        
                        let required = false
                        this.options.forEach(option => {    
                            if (option.required && selection.column === option.column) {
                                required = true
                            }
                        })

                        if (selection.id === undefined) {
                            this.filterConditions = []
                        } else {
                            if (selection.id.length > 1) {
                                this.filterConditions.push({
                                    AndOr: 'AND',
                                    column: selection.column,
                                    operator: 'IN',
                                    value: selection.id,
                                    required
                                })
                            } else {
                                this.filterConditions.push({
                                    AndOr: 'AND',
                                    column: selection.column,
                                    operator: '=',
                                    value: selection.id,
                                    required
                                })
                            }             
                        }
                    })
                }
            }
        },

        setNoDataMessage(message) {
            this.noDataMessage = message
        },

        setNoData(payload) {
            this.noData = payload
        },

        setFilterConditions(array) {
            this.filterConditions = array
            
            if (array.length === 0) {
                this.clearFilters = true
            }

            this.options.forEach(option => {
                if (option.required) {
                    this.loadTable = true
                } 
            })
        },

        setClearFilters(payload) {
            this.clearFilters = payload
        },

        clearNotRequiredOptionsItems() {
            this.options.forEach(option => {
                if (!option.required) {
                    option.items = []
                } 
            })
        },

        setLoadTable(payload) {
            this.loadTable = payload
        },

        sendUnsaved(e) {
            this.$emit('unsavedChanges', e)
        }
    },
}
</script>

<style>
</style>